import NLink from 'next/link';
import { Link as TUILink, LinkProps, SxStyleProp } from 'theme-ui';
import { ArrowRightIcon } from './icons';
import { text as textStyles, TextStyles } from 'styles/theme';

export interface SRLinkProps extends Omit<LinkProps, 'as'> {
  href: string;
  as?: string;
  external?: boolean;
  text?: keyof TextStyles;
  variant?: string;
  prefetch?: boolean;
  sx?: SxStyleProp;
}

interface ArrowLinkProps extends SRLinkProps {
  arrowColor?: string;
  arrowMarginLeft?: number;
  arrowSize?: number;
}

export function Link({
  href,
  as,
  external = false,
  text = 'default',
  sx,
  variant = 'default',
  prefetch = false,
  ...rest
}: SRLinkProps) {
  // Leave out color & lineHeight so it doesn't overwrite the link variant
  const { color, lineHeight, ...textStylesWithoutColor } = textStyles[text];
  let styles = {
    ...sx,
  };
  if (variant !== 'inherit') {
    styles = { ...textStylesWithoutColor, ...styles };
  }
  if (external) {
    return <TUILink href={href} sx={styles} variant={variant} {...rest} />;
  } else {
    return (
      <NLink href={href} as={as} prefetch={prefetch} passHref>
        <TUILink sx={styles} variant={variant} {...rest} />
      </NLink>
    );
  }
}

export function LinkUnstyled({ ...rest }: SRLinkProps) {
  return <Link variant="unstyled" {...rest} />;
}

export function LinkArrow({
  arrowColor = 'brandBlue',
  arrowMarginLeft = 4,
  arrowSize = 24,
  children,
  href,
  ...rest
}: ArrowLinkProps) {
  return (
    <Link variant="links.arrow" href={href} {...rest}>
      {children}
      <ArrowRightIcon
        ml={arrowMarginLeft}
        color={arrowColor}
        size={arrowSize}
      />
    </Link>
  );
}

export function LinkButton({ text = 'emphasis', ...rest }: SRLinkProps) {
  return <Link variant="primaryButton" text={text} {...rest} />;
}

export function LinkButtonHollow({ text = 'emphasis', ...rest }: SRLinkProps) {
  return <Link variant="primaryButtonHollow" text={text} {...rest} />;
}

export function LinkButtonSecondary({
  text = 'emphasis',
  ...rest
}: SRLinkProps) {
  return <Link variant="secondaryButton" text={text} {...rest} />;
}

export function LinkButtonArrow({
  arrowSize = 32,
  arrowColor = 'textInverted',
  arrowMarginLeft = 4,
  children,
  href,
  text = 'emphasis',
  ...rest
}: ArrowLinkProps) {
  return (
    <Link variant="links.buttonWithIcon" href={href} text={text} {...rest}>
      {children}
      <ArrowRightIcon
        ml={arrowMarginLeft}
        color={arrowColor}
        size={arrowSize}
      />
    </Link>
  );
}

export function LinkButtonArrowSecondary({
  arrowSize = 32,
  arrowColor = 'text',
  arrowMarginLeft = 4,
  children,
  href,
  text = 'emphasis',
  ...rest
}: ArrowLinkProps) {
  return (
    <Link
      variant="links.buttonWithIconSecondary"
      href={href}
      text={text}
      {...rest}
    >
      {children}
      <ArrowRightIcon
        ml={arrowMarginLeft}
        color={arrowColor}
        size={arrowSize}
      />
    </Link>
  );
}

export function LinkButtonArrowHollow({
  arrowSize = 32,
  arrowColor = 'brandBlue',
  arrowMarginLeft = 4,
  children,
  href,
  text = 'emphasis',
  ...rest
}: ArrowLinkProps) {
  return (
    <Link
      variant="links.buttonWithIconHollow"
      href={href}
      text={text}
      {...rest}
    >
      {children}
      <ArrowRightIcon
        ml={arrowMarginLeft}
        color={arrowColor}
        size={arrowSize}
      />
    </Link>
  );
}
