import { Box } from 'theme-ui';

export const SVG = ({
  size = 32,
  fill = 'none',
  stroke = 'text',
  sx = {},
  viewBox = '0 0 32 32',
  ...props
}) => (
  <Box
    as="svg"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    width={size + ''}
    height={size + ''}
    viewBox={viewBox}
    sx={{ stroke, fill, ...sx }}
    {...props}
  />
);

export const ArrowRightIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} {...props} role="img" aria-label="Arrow pointing right">
    <path
      d="M18.6667 9.33325L25.3333 15.9999L18.6667 22.6666M5.33334 15.9999L25 15.9999"
      strokeWidth="2"
    />
  </SVG>
);

export const CrossIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} {...props}>
    <path d="M23 23L9 9M9 23L23 9" />
  </SVG>
);

export const DownloadIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} {...props}>
    <path
      d="M10.6667 14.6667L16 20.0001L21.3333 14.6667M16 5V19.5M25.5 23V26.4999H6.5L6.5 23"
      strokeWidth="2"
    />
  </SVG>
);

export const MenuIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} {...props}>
    <path d="M5 8.5L27 8.5M5 15.5H27M5 22.5H27" />
  </SVG>
);

export const HeartIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} {...props}>
    <path
      d="M7.50758 16.6448C5.49747 14.6672 5.49748 11.4608 7.50758 9.48321C9.51768 7.5056 12.7767 7.5056 14.7868 9.48321L16 10.6768L17.2132 9.48322C19.2233 7.5056 22.4823 7.5056 24.4924 9.48321C26.5025 11.4608 26.5025 14.6672 24.4924 16.6448L16 25L7.50758 16.6448Z"
      strokeWidth="2"
    />
  </SVG>
);

export const PatentsIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} {...props}>
    <path d="M16 5.8785L17.2885 7.73997L17.5639 8.1378L17.9705 7.87564L19.8733 6.64896L20.3514 8.86182L20.4536 9.33475L20.9296 9.24817L23.157 8.84302L22.7518 11.0704L22.6652 11.5464L23.1382 11.6486L25.351 12.1267L24.1244 14.0295L23.8622 14.4361L24.26 14.7115L26.1215 16L24.26 17.2885L23.8622 17.5639L24.1244 17.9705L25.351 19.8733L23.1382 20.3514L22.6652 20.4536L22.7518 20.9296L23.157 23.157L20.9296 22.7518L20.4536 22.6652L20.3514 23.1382L19.8733 25.351L17.9705 24.1244L17.5639 23.8622L17.2885 24.26L16 26.1215L14.7115 24.26L14.4361 23.8622L14.0295 24.1244L12.1267 25.351L11.6486 23.1382L11.5464 22.6652L11.0704 22.7518L8.84302 23.157L9.24817 20.9296L9.33475 20.4536L8.86182 20.3514L6.64896 19.8733L7.87564 17.9705L8.1378 17.5639L7.73997 17.2885L5.8785 16L7.73997 14.7115L8.1378 14.4361L7.87564 14.0295L6.64896 12.1267L8.86182 11.6486L9.33475 11.5464L9.24817 11.0704L8.84302 8.84302L11.0704 9.24817L11.5464 9.33475L11.6486 8.86182L12.1267 6.64896L14.0295 7.87564L14.4361 8.1378L14.7115 7.73997L16 5.8785Z" />
  </SVG>
);

export const ImportIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} viewBox="0 0 56 56" {...props}>
    <path
      d="M23.625 22.75L23.625 11.375L44.625 11.375L44.625 44.625L23.625 44.625L23.625 31.5M7 27.125L35.875 27.125M29.75 34.125L36.75 27.125L29.75 20.125"
      stroke={color}
    />
  </SVG>
);

export const ResumesIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} viewBox="0 0 56 56" {...props}>
    <path
      d="M17.5 12.25V43.75M12.25 14V42M22.75 11.375H45.5V44.625H22.75V11.375Z"
      stroke={color}
    />
  </SVG>
);

export const FiltersIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} viewBox="0 0 56 56" {...props}>
    <path
      d="M10.5 17.5L35 17.5M22.75 27.125H45.5M10.5 36.75H29.75M15.75 27.125H10.5M36.75 36.75H45.5M45.5 17.5H42"
      stroke={color}
    />
    <circle cx="38.5" cy="17.5" r="3.5" stroke={color} />
    <circle cx="19.25" cy="27.125" r="3.5" stroke={color} />
    <circle cx="33.25" cy="36.75" r="3.5" stroke={color} />
  </SVG>
);

export const CheckCircleIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} {...props}>
    <path d="M26 16C26 21.5228 21.5228 26 16 26C10.4772 26 6 21.5228 6 16C6 10.4772 10.4772 6 16 6C21.5228 6 26 10.4772 26 16Z" />
    <path d="M11 17.4085L13.6915 20L21 13" />
  </SVG>
);

export const BriefcaseIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} viewBox="0 0 56 56" {...props}>
    <path
      d="M34.125 18.375V11.375H21.875V18.375M7.875 27.125V44.625H48.125V27.125M7.875 27.125V18.375H48.125V27.125M7.875 27.125H48.125M37.625 24.5V29.75M18.375 24.5V29.75"
      stroke={color}
    />
  </SVG>
);

export const CursorIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} viewBox="0 0 56 56" {...props}>
    <path
      d="M28 18.375V37.625M28 18.375H22.75M28 18.375H33.25M28 37.625H22.75M28 37.625H33.25M9.625 9.625H46.375V46.375H9.625L9.625 9.625Z"
      stroke={color}
    />
  </SVG>
);

export const ParagraphIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} viewBox="0 0 56 56" {...props}>
    <path
      d="M7 14.875H49M7 20.125H49M7 25.375H42M12.25 30.625H49M7.00002 30.625H9.33335M12.25 35.875H49M7.00002 35.875H9.33335M7.00002 41.125H9.33335M12.25 41.125H42"
      stroke={color}
    />
  </SVG>
);

export const LightbulbIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} viewBox="0 0 56 56" {...props}>
    <path
      d="M24.5 37.3333H35M26.25 21.875H33.25M29.75 47.25C26.8505 47.25 24.5 44.8995 24.5 42V38.4663C24.5 36.2225 23.7363 34.0454 22.3346 32.2933L20.3389 29.7987C18.5012 27.5015 17.5 24.6473 17.5 21.7055V21C17.5 14.2345 22.9845 8.75 29.75 8.75C36.5155 8.75 42 14.2345 42 21V21.7055C42 24.6473 40.9988 27.5015 39.1611 29.7987L37.1654 32.2933C35.7637 34.0454 35 36.2225 35 38.4663V42C35 44.8995 32.6495 47.25 29.75 47.25Z"
      stroke={color}
    />
  </SVG>
);

export const DesignIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} viewBox="0 0 56 56" {...props}>
    <circle cx="28" cy="19.25" r="11.75" stroke={color} />
    <path
      d="M41.4526 22.1992C47.0725 25.4438 48.998 32.63 45.7534 38.25C42.5087 43.8699 35.3225 45.7954 29.7026 42.5508C24.0826 39.3061 22.1571 32.1199 25.4018 26.5C28.6464 20.88 35.8326 18.9545 41.4526 22.1992Z"
      stroke={color}
    />
    <path
      d="M26.2974 42.5508C20.6775 45.7955 13.4913 43.87 10.2466 38.25C7.00197 32.6301 8.9275 25.4439 14.5474 22.1992C20.1674 18.9546 27.3536 20.8801 30.5982 26.5C33.8429 32.12 31.9174 39.3062 26.2974 42.5508Z"
      stroke={color}
    />
  </SVG>
);

export const EnvelopeIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} viewBox="0 0 64 64" {...props}>
    <path d="M55 18.6003V17H9V18.6003M55 18.6003V47H9V18.6003M55 18.6003L32 37L9 18.6003" />
  </SVG>
);

export const GradCapIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} viewBox="0 0 64 64" {...props}>
    <path
      d="M49 29V40L32.6071 48L15 40V29M44 31V53M5 24L31.0357 12L59 24L32.9643 36L5 24Z"
      stroke={color}
    />
  </SVG>
);

export const ParagraphsIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} {...props}>
    <path d="M2 11.5H10M2 14.5H10M12 14.5L20 14.5M12 11.5L20 11.5M22 14.5H30M22 11.5H30M2 17.5H10M12 17.5L20 17.5M22 17.5H30M2 20.5H8M12 20.5H17M22 20.5H28" />
  </SVG>
);

export const SquaresIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} {...props}>
    <path d="M17.5 5.5H26.5V14.5H17.5V5.5Z" />
    <path d="M5.5 5.5H14.5V14.5H5.5V5.5Z" />
    <path d="M17.5 17.5H26.5V26.5H17.5V17.5Z" />
    <path d="M5.5 17.5H14.5V26.5H5.5V17.5Z" />
  </SVG>
);

export const PlusIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} {...props}>
    <path d="M7 16H25M16 25V7" strokeWidth="2" />
  </SVG>
);

export const MinusIcon = ({ color = 'text', ...props }) => (
  <SVG stroke={color} {...props}>
    <path d="M7 16H25" strokeWidth="2" />
  </SVG>
);
